import TRANSLATIONS_EN from "./locale/translations/en.json";
import TRANSLATIONS_NL from "./locale/translations/nl.json";
import TRANSLATIONS_DE from "./locale/translations/de.json";
import { getPayApi } from "./custom/settings";

type PayProduct = {
    ProductName: string;
    Quantity: number;
    ImageUrl: string;
    PriceIncTax: number;
    PriceExTax: number;
};

interface PayOrderSummary {
    TotalAmount: number;
    ShippingCosts: number;
    ShippingCostExTax: number;
    ShippingCostIncTax: number;
    TaxCosts: number;
    CouponDiscount: number;
    VoucherPayments: any[];
    Products: PayProduct[];
}

const getOrderSummaryById = async (id: any, orderToken: any) => {
    const fetchOptions = {
        method: "GET",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "X-Store-Hash": window.__pay__.settings.store_hash,
            "X-Order-Token": orderToken || "",
        },
    };

    const response = await fetch(`${getPayApi()}getorderconfirmationrecords/${id}`, fetchOptions);
    const res = await response.json();
    return res;
};

function addStylesToHead() {
    // add a style tag to hide the head
    const style = document.createElement("style");

    style.innerHTML = ` 

  .pay-layout {
    display:flex;
    flex-direction:column;

  }
  
  @media (min-width: 768px) {
    .pay-layout {
      display:flex;
      flex-direction:row;
    }
    .pay-layout .pay-layout-main {
      flex:2;
      margin-right: 2rem;

    }
    .pay-layout .pay-layout-cart {
      flex:1;

    }

  }
  .pay-layout .pay-layout-cart h5, .pay-layout .pay-layout-cart p {
    margin:0;
    padding:0;
  }
  .pay-cart {
    border:1px solid ${window.__pay__.theme_settings["optimizedCheckout-orderSummary-borderColor"]};
    border-radius: 5px;
  }
  .pay-cart-header , .pay-cart-section {
    padding: 1rem;
  }
  .pay-cart-section {
    border-top:1px solid ${window.__pay__.theme_settings["optimizedCheckout-orderSummary-borderColor"]};
  }
  .pay-cart-header h3 {
    margin:0;
    padding:0;
  }

  .pay-cart-items {
      list-style: none;
      padding: 0;
      margin: 0;
      display:flex;
      flex-direction:column;
  }
  .pay-cart-item {
      display:flex;
      align-items: center;
      justify-content: space-around;
  }
  .pay-cart-item-image {
    flex-basis: 20%;
  }
  .pay-cart-item-info {
    padding: 0 1rem;
    flex-basis: 60%;
  }
  .pay-cart-item-price {
    flex-basis: 20%;
    text-align:right;
  }
  .pay-logo-block {
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content:center;
  }
  .pay-price {
    display:flex;
    justify-content:space-between;
  }
  .pay-price.pay-price--total {
    font-weight:bold;
  }
  .pay-order-loader {
    display:flex;
    align-items:center;
    justify-content:center;
    min-height: 300px;
  } 
  .pay-order-loader-circle {
    border: 6px solid #f3f3f3;
    border-radius: 50%;
    border-top: 6px solid #555;
    width: 50px;
    height: 50px;
    animation: pay-spin 2s linear infinite;
    
  }
  .pay-footer {
    margin-top: 2rem;
  }



  @keyframes pay-spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
                   
`;
    document.head.appendChild(style);
}

function renderProducts(products: PayProduct[], translations: any) {
    const isTaxIncluded = true;
    const renderProducts = products
        .map((product) => {
            const productPrice = isTaxIncluded ? product.PriceIncTax : product.PriceExTax;
            return `
            
            <li class="pay-cart-item">
                <div class="pay-cart-item-image">
                    <img alt="${product.ProductName}" src="${product.ImageUrl}" />
                </div>
                <div class="pay-cart-item-info">
                    <h5 class="pay-cart-item-name">${product.ProductName}</h5>
                    <p class="pay-order-item-qty">x ${product.Quantity}</p>
                </div>
                <div class="pay-cart-item-price">${productPrice.toFixed(2)}</div>
            </li>`;
        })
        .join("");

    return `
  <p class="items-quantity">${products.length} ${translations.items}</p>
  <ul class="pay-cart-items">${renderProducts}</ul>
  `;
}

function renderCoupons(data: PayOrderSummary, translations: any) {
    const couponsAmount = data.VoucherPayments.reduce(
        (acc, coupon) => acc + parseFloat(coupon.Amount),
        0
    );

    return couponsAmount
        ? `<div class="price coupon">
        <p>${translations.vvv_giftcard}</p>
        <p class="order-coupon-amount">${couponsAmount.toFixed(2)}</p>
      </div>`
        : "";
}

function renderOrderTotal(data: PayOrderSummary, translations: any) {
    const isTaxIncluded = true;
    const grandTotal = Number(data.TotalAmount);
    const tax = Number(data.TaxCosts);
    const shippingPrice = Number(isTaxIncluded ? data.ShippingCostIncTax : data.ShippingCostExTax);
    const shippingPriceVisual = shippingPrice.toFixed(2);
    const subtotal = (grandTotal - (isTaxIncluded ? 0 : tax) - shippingPrice).toFixed(2);

    return `<div class="pay-order-total">
            <div class="pay-price">
              <p>${translations.subtotal}</p>
              <p class="order-subtotal">${subtotal}</p>
            </div>
            ${renderCoupons(data, translations)}
            <div class="pay-price">
                <p>${translations.shipping_cost}</p>
                <p class="order-shipping-price">${shippingPriceVisual}</p>
            </div>
            <div class="pay-price">
                <p>${translations.vat}</p>
                <p class="order-btw">${tax.toFixed(2)}</p>
            </div>
          </div>
          <div class="pay-price pay-price--total">
              <p>${translations.total}</p>
              <p>${grandTotal.toFixed(2)}</p>
          </div>`;
}

function renderPayoff(translations: any) {
    return `
  <div class="pay-logo-block">
    <span class="logo-block-heading">${translations.pay_footer}</span>
    <div class="logo-block-logo">
      <svg
        width="39"
        height="24"
        viewBox="0 0 39 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M32.9063 24H0.533203V5.56324C0.533203 2.49433 3.03085 0 6.09645 0H32.903C35.9719 0 38.4662 2.49765 38.4662 5.56324V18.4334C38.4695 21.5024 35.9752 24 32.9063 24Z"
          fill="#585FFF"
        />
        <path
          d="M20.4812 8.71522C19.8701 8.34987 19.1527 8.14062 18.3921 8.14062C16.1336 8.14062 14.2969 9.97733 14.2969 12.2358C14.2969 14.4943 16.1336 16.331 18.3921 16.331C19.156 16.331 19.8701 16.1218 20.4812 15.7565V16.2812H22.484V8.18048H20.4812V8.71522ZM18.3921 14.325C17.2396 14.325 16.303 13.3883 16.303 12.2358C16.303 11.0833 17.2396 10.1467 18.3921 10.1467C19.5446 10.1467 20.4812 11.0833 20.4812 12.2358C20.4812 13.385 19.5446 14.325 18.3921 14.325Z"
          fill="white"
        />
        <path
          d="M10.0127 5.53003H6.05371V16.2779H8.05648V13.023H10.0526C12.1251 13.023 13.8124 11.3291 13.7991 9.25325C13.7858 7.18738 12.0786 5.53003 10.0127 5.53003ZM10.0227 11.0202H8.05648V7.5328H10.0526C11.0258 7.5328 11.8129 8.33324 11.793 9.30971C11.7764 10.2629 10.9759 11.0202 10.0227 11.0202Z"
          fill="white"
        />
        <path
          d="M33.1754 16.3476C33.8504 16.3476 34.3976 15.8004 34.3976 15.1253C34.3976 14.4503 33.8504 13.9031 33.1754 13.9031C32.5003 13.9031 31.9531 14.4503 31.9531 15.1253C31.9531 15.8004 32.5003 16.3476 33.1754 16.3476Z"
          fill="white"
        />
        <path
          d="M30.3154 8.15054L27.9639 14.2352L25.5427 8.18043H23.3506L26.5889 16.2779H27.1734L27.1635 16.3044C26.8579 17.0982 26.0807 17.6296 25.2305 17.6296H24.9946V19.6324H25.2637C26.9343 19.6324 28.4654 18.5862 29.0666 17.0252L32.4976 8.14722H30.3154V8.15054Z"
          fill="white"
        />
      </svg>
    </div>
  </div>`;
}

function getClosingHeader(translations: any) {
    const customer = window.__pay__.customer;

    return customer
        ? (translations.closing_header_personal as string).replace("{name}", customer.name)
        : translations.closing_header;
}

async function renderOrder(orderId: any, orderToken: any, translations: any) {
    const data = await getOrderSummaryById(orderId, orderToken);
    const dataPayOrder = document.querySelector("[data-pay-order]");
    if (dataPayOrder) {
        dataPayOrder.innerHTML = `
 
   <header class="pay-cart-header">
     <h3>${translations.order_overview}</h3>
   </header>

   <section class="pay-cart-section">
     ${renderProducts(data.Products, translations)}
   </section>
   <section class="pay-cart-section">
     ${renderOrderTotal(data, translations)}
   </section>
`;
    }
}

function hideCustomElements() {
    if (window?.__pay__custom__?.hide) {
        //hide alle custom elements
        Object.values(window.__pay__custom__.hide).forEach((value) => {
            if (typeof value === "string") {
                const toHide = document.querySelector(value) as HTMLElement;
                if (toHide) {
                    toHide.style.display = "none";
                }
            }
        });
    }
}

async function renderIfOrderConfirmationPage(translations: any) {
    //check if we are on the order confirmation page
    const payOrderConfirmationPageDefaultElement = document.querySelector(
        '[data-pay-confirmation="default"]'
    );
    const payOrderConfirmationPageElementCustom = document.querySelector(
        '[data-pay-confirmation="custom"]'
    );

    const payOrderConfirmationPageElement = payOrderConfirmationPageElementCustom
        ? payOrderConfirmationPageElementCustom
        : payOrderConfirmationPageDefaultElement;

    if (payOrderConfirmationPageElement) {
        const urlParams = new URLSearchParams(window.location.search);
        const orderId = urlParams.get("orderId");
        const orderToken = urlParams.get("orderToken");

        if (orderId && orderToken) {
            addStylesToHead();

            hideCustomElements();

            payOrderConfirmationPageElement.innerHTML = `
        <div class="pay-layout">
          <div class="pay-layout-main">
              <h1 class="optimizedCheckout-headingPrimary">${getClosingHeader(translations)}</h1>
              <p>${translations.closing_text}</p>
              <p>${translations.order}<strong>&nbsp;#${orderId}</strong></p>

              <div class="pay-button-wrapper">
                  <a class="pay-button-continue button" href="/">${
                      translations.continue_shopping_action
                  }</a>
              </div>
              ${renderPayoff(translations)}
          </div>
          <aside  class="pay-layout-cart">
            <article class="pay-cart" data-pay-order >
              <div class="pay-order-loader">
                  <div class="pay-order-loader-circle"></div>
              </div>
            </article>
          </aside>
        </div>
       
       
        `;

            try {
                await renderOrder(orderId, orderToken, translations);
            } catch (e) {
                console.error("error", e);
            }
        }
    }
}

(async function orderconfirmation() {
    //TODO load translations dynamically but how ?.
    let translations: any = {};

    switch (window.__pay__.settings.langJson.locale.toLocaleLowerCase()) {
        case "nl":
            translations = TRANSLATIONS_NL.optimized_checkout.pay;
            break;
        case "de":
            translations = TRANSLATIONS_DE.optimized_checkout.pay;
            break;
        default:
            translations = TRANSLATIONS_EN.optimized_checkout.pay;
    }

    renderIfOrderConfirmationPage(translations);
})();
