export const settings = {
    debounceTime: 1000,
    resetTime: 5000,
    api: {
        postcode_api: "https://app.bc-pay.nl/",
        postcode_api_key: "1c07c483-c2dd-4ff0-8dbe-58d2b8646c99", // can be anything, because it's not used. however, this looks cool :-)
        pay_api: "https://fns.bc-pay.nl/api/",
        pay_checkout: "https://app.bc-pay.nl/checkout/",
    },
};

function getOverrideSettings() {
    if (window.__pay__override__) {
        return window.__pay__override__;
    }
    return {};
}

export function getPayCheckoutApi() {
    const customSettings = getOverrideSettings();
    if (customSettings?.api?.pay_checkout) {
        return customSettings?.api?.pay_checkout;
    }
    return settings.api.pay_checkout;
}
export function getPayApi() {
    const customSettings = getOverrideSettings();
    if (customSettings?.api?.pay_api) {
        return customSettings?.api?.pay_api;
    }
    return settings.api.pay_api;
}

export function getPostcodeApi() {
    const customSettings = getOverrideSettings();
    if (customSettings?.api?.postcode_api) {
        return customSettings?.api?.postcode_api;
    }
    return settings.api.postcode_api;
}

export function getPostcodeApiKey() {
    const customSettings = getOverrideSettings();
    if (customSettings?.api?.postcode_api_key) {
        return customSettings?.api?.postcode_api_key;
    }
    return settings.api.postcode_api_key;
}
